<template>
   <section class="cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>OMS</el-breadcrumb-item>
            <el-breadcrumb-item>智能问答</el-breadcrumb-item>
            <el-breadcrumb-item>词库词条</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row >

      <!-- 主体内容 -->
      <el-row class="cont-main">
         <el-col :span="5">
            <el-table
                    ref="sideTable"
                    :data="sideData"
                    highlight-current-row
                    @current-change="handleSelect"
                    tooltip-effect="dark"
                    style="width: 100%">
               <el-table-column type="selection" width="50"></el-table-column>
               <el-table-column label="词库" width="140">
                  <template slot-scope="scope">
                     <el-input  v-if="scope.row.append" maxLength="14" v-model="scope.row.wordName" @keyup.enter.native="saveThesaurus(scope.row.wordName)"></el-input>
                     <span v-else>{{scope.row.wordName}}</span>
                  </template>
               </el-table-column>
               <el-table-column align="right">
                  <template slot="header">
                     <span style="font-size: 18px">
                        <!--<i class="el-icon-download" style="margin-right: 10px"></i>-->
                        <i @click="sideData.unshift({ append: true })" class="el-icon-plus"></i>
                     </span>
                  </template>
                  <template slot-scope="scope">
                     <span v-if="!scope.row.append">
                        <i class="el-icon-edit" @click="editThesaurus(scope.row)" style="margin-right: 10px"></i>
                        <i class="el-icon-delete" @click.stop="delThesaurus(scope.row.id, scope.row.wordName)"></i>
                     </span>
                  </template>
               </el-table-column>
            </el-table>
         </el-col>
         <el-col :span="19">
            <el-row class="cont-top">
               <el-row class="query">
                  <!--<span class="label">词条名称：</span>
                  <el-input class="width-175 m-bottom-5 m-right-10" v-model="wordName" placeholder="词条名称"  clearable></el-input>-->
               </el-row>
               <el-row class="add">
                  <el-button class="m-bottom-5" type="primary" icon="el-icon-plus"  @click="tableData.unshift({ append: true })" plain>新增词条</el-button>
                  <el-button class="m-bottom-5 bg-gradient" type="primary" icon="el-icon-plus"  @click="isShow = true">批量新增</el-button>
               </el-row>
            </el-row>
            <el-row style="padding: 0 30px">
               <el-table
                       ref="multipleTable"
                       :data="tableData"
                       tooltip-effect="dark"
                       style="width: 100%">
                  <el-table-column label="词条值" max-width="720">
                     <template slot-scope="scope">
                        <el-input  v-if="scope.row.append" v-model="scope.row.wordName"></el-input>
                        <span v-else>{{scope.row.wordName}}</span>
                     </template>
                  </el-table-column>
                  <!--<el-table-column label="同义词(多个词用逗号分开)">
                     <template slot-scope="scope">
                        <el-input  v-if="scope.row.append" v-model="scope.row.synonym"></el-input>
                        <span v-else>{{scope.row.synonym}}</span>
                     </template>
                  </el-table-column>-->
                  <el-table-column label="启用词条" width="160">
                     <template slot-scope="scope" v-if="!scope.row.append">
                        <el-switch v-model="scope.row.state" @change="changeEntryS
                        tate(scope.row)" :active-value="true" :inactive-value="false"></el-switch>
                     </template>
                  </el-table-column>
                  <el-table-column label="操作" width="140">
                     <template slot-scope="scope">
                        <span v-if="!scope.row.append">
                           <el-button type="text" icon="el-icon-edit" @click="editEntry(scope.row)" >
                              <el-link type="primary">编辑</el-link>
                           </el-button>
                           <el-button type="text" style="color: #F56C6C;" icon="el-icon-delete" @click="delEntry(scope.row)" >
                              <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                           </el-button>
                        </span>
                        <el-button type="text" v-else icon="el-icon-finished" @click="saveEntry(scope.row)" >
                           <el-link type="primary" >保存</el-link>
                        </el-button>
                     </template>
                  </el-table-column>
               </el-table>
            </el-row>
         </el-col>
      </el-row>
      <!-- 批量增加 -->
      <el-dialog title="批量增加" :before-close="closeEntry" :visible.sync="isShow" width="550px">
         <el-form label-width="105px">
         <el-row class="m-bottom-15">
            <el-input type="textarea" v-model="wordNames"></el-input>
            <p class="m-top-15">请输入词条，每行一个词条。同义词用','分开，回车换行。</p>
         </el-row>
      </el-form>
         <span slot="footer" class="dialog-footer">
            <el-button  @click="resetForm()" v-text="$t('msg.reset')">重置</el-button>
            <el-button class="bg-gradient" type="primary" @click="saveBatchEntry" v-text="$t('msg.save')">保存</el-button>
         </span>
     </el-dialog>
   </section>
</template>

<script>
   import { urlObj } from '@/api/interface'
   import { smartFaq } from "@/api/interface/oms";
   export default {
      data(){
         return{
            hotelName: '',          // 酒店名称
            sideData: [],           // 侧边栏数据
            tableData: [],          // 表格数据
            wordName: '',           //
            wordNames: '',          //
            oldWordName: '',        // 旧词条
            synonym: '',
            parentId: '',           // 词库ID
            lexicon: '',            // 词库
            isShow: false,          //
            index: 1,
            /* 提示文本 */
            hotelId_no_null: '请选择酒店！',
            parentId_no_null: '请选择词库！',
            wordName_no_null: '词条值不能为空！',
            add_success: "添加成功！",
            del_success: "删除成功！",
            update_success: "修改成功！",
            confirm: '确定',
            cancel: '取消',
            confirm_remove: '确定移除？',
            prompt: '提示！',
            release_success: '发布成功！'
         }
      },
      mounted() {
         this.getThesaurus()
      },
      methods: {
         // 获取词库列表
         getThesaurus(){
            const url = smartFaq.thesaurus
            const param = { leaf: false }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.sideData = res.records
               }
            })
         },
         // 编辑词库
         editThesaurus(row){
            this.$set(row, 'append', true)
         },
         // 保存词库
         saveThesaurus(wordName){
            const url = smartFaq.addFaqWord
            const param = {
               wordName,
               leaf: false
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.$message({ message: '添加成功！', type: 'success' })
                  this.getThesaurus()
               }
            })
         },
         // 删除词库
         delThesaurus(id, wordName){
            this.$confirm(this.confirm_remove, this.prompt, {
               confirmButtonText: this.confirm,
               cancelButtonText: this.cancel,
               type: 'warning'
            }).then(() => {
               const url = smartFaq.delFaqWord
               const param = { id, wordName, hotelId: this.hotelInfo.id }
               this.$axios.post(url, param).then(res => {
                  if (res.success){
                     this.getThesaurus()
                     this.$message({ message: '删除成功！', type: 'success' })
                  }
               })
            })
         },
         // 点击选中词库
         handleSelect(row){
            if (!this.hotelInfo.id) return this.$message.error('请选中酒店！')
            this.parentId = row.id
            this.wordName = row.wordName
            this.lexicon = row.wordName
            this.getEntry()
         },
         // 获取词条
         getEntry(){
            const url = smartFaq.entries
            const param = { hotelId: this.hotelInfo.id, parentId: this.parentId }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  let tableData = res.records
                  tableData.forEach(item => {
                     if (item.hasOwnProperty('hotelId')){
                        item.state = true
                        return
                     }
                     item.state = false
                  })
                  this.tableData = tableData
               }
            })
         },
         // 编辑词条
         editEntry(row){
            this.oldWordName = row.wordName
            this.$set(row, 'append', true)
         },
         // 关闭批量弹窗
         closeEntry(){
            this.isShow = false
            this.wordNames = ''
         },
         // 保存词条
         saveEntry(row){
            if (!this.hotelInfo.id) {
               this.$alert(this.hotelId_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (!this.parentId) {
               this.$alert(this.parentId_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (!this.wordName) {
               this.$alert(this.wordName_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }

            let url = smartFaq.addFaqWord
            let param = {
               hotelId: this.hotelInfo.id,
               wordName: row.wordName,
               lexicon: this.wordName,
               synonym: row.synonym ? row.synonym.replace(/，/g, ',') : '',
               parentId: this.parentId,
               leaf: true
            }
            let action
            if (row.hasOwnProperty('id')) action = 'edit'
            // 编辑时
            if (action === 'edit'){
               url = smartFaq.updateFaqWord
               param.wordNames = this.oldWordName  // 旧值
               param.id = row.id
            }
            this.$axios.post(url, param).then(res => {
               if (res.success){
                  this.getEntry()
                  this.$message({
                     message: action === 'edit' ? '编辑成功！' : '添加成功！',
                     type: 'success'
                  })
               }
            })
         },
         // 批量保存词条
         saveBatchEntry(){
            if (!this.hotelInfo.id) {
               this.$alert(this.hotelId_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (!this.parentId) {
               this.$alert(this.parentId_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            const url = smartFaq.addBatchThesaurus
            const param = {
               hotelId: this.hotelInfo.id,
               lexicon: this.wordName,
               parentId: this.parentId,
               leaf: true,
               wordNames: this.wordNames.replace(/，/g, ',').replace(/\s/g, '-')
            }
            this.$axios.post(url, param).then(res => {
               if (res.success){
                  this.isShow = false
                  this.getEntry()
                  this.$message({ message: '批量添加成功！', type: 'success' })
               }
            })
         },
         // 删除词条
         delEntry(row){
            this.$confirm(this.confirm_remove, this.prompt, {
               confirmButtonText: this.confirm,
               cancelButtonText: this.cancel,
               type: 'warning'
            }).then(() => {
               const url = smartFaq.delFaqWord
               const param = {
                  id: row.id,
                  lexicon: row.lexicon,
                  hotelId: this.hotelInfo.id,
                  wordName: row.wordName
               }
               this.$axios.post(url, param).then(res => {
                  if (res.success){
                     this.getEntry()
                     this.$message({ message: '删除成功！', type: 'success' })
                  }
               })
            })
         },
         // 启用词条
         changeEntryState(row){
            if (!this.hotelInfo.id) {
               this.$alert(this.hotelId_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (!this.parentId) {
               this.$alert(this.parentId_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            let url = smartFaq.enableFaqWord
            if (!row.state) url = smartFaq.disableFaqWord
            const param = {
               hotelId: this.hotelInfo.id,
               id: row.id,
               wordName: row.wordName,
               lexicon: this.lexicon
            }
            this.$axios.post(url, param).then(res => {
               if (res.success){
                  this.$message({
                     message: row.state ? '启动词条' : '禁用词条',
                     type: 'success'
                  })
               }
            })
         }
      }
   }
</script>

<style scoped>
   .cont .cont-main{
      padding:50px;
      background: #ffffff;
   }
   .cont .cont-top{
      font-size: 14px;
      background-color: #FFFFFF;
      padding: 5px 10px 0;
      border-bottom: 1px solid #EBEEF5;
   }
   .cont .cont-top .label{
      line-height: 26px;
   }
   .cont .cont-top .query{
      display: flex;
      float: left;
   }
   .cont .cont-top .add{
      float: right;
   }
   /deep/ .el-textarea__inner{
      height: 240px;
   }
</style>
